import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";

import STACK_ABI from "../abi/stack.json";
import WBNB from "../abi/WBNB.json";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";

function Listings() {
  var Router = "0xD71214DCF9199e87879f83815dB9bC3Af209BE7e";
  let authState = useSelector((state) => state.auth);
  var { isConnected } = authState;
  const { library, account } = useWeb3React();
  const web3Obj = library;

  const [dipositAmount, setDipositAmount] = useState("");
  const [timeperiod, setTimeperiod] = useState(0);
  const [timeperiodDate, setTimeperiodDate] = useState(
    moment().add(30, "days").format("DD/MM/YYYY h:mm A")
  );
  //   const [withdrawAmount, setWithdrawAmount] = useState("");
  const [balance, setBalance] = useState(0);

  const { deactivate } = useWeb3React();
  const dispatch = useDispatch();
  const disconnectWallet = () => {
    deactivate();
    dispatch({
      type: "WALLET_DISCONNECT",
      payload: {
        account: "",
        isConnected: false,
        providerType: "",
      },
    });
  };

  const [stackContractInfo, setStackContractInfo] = useState({
    totalStakers: 0,
    totalStakedToken: 0,
  });
  const [stakersInfo, setStakersInfo] = useState({
    totalStakedTokenUser: 0,
    totalUnstakedTokenUser: 0,
    totalClaimedRewardTokenUser: 0,
    currentStaked: 0,
    realtimeReward: 0,
    stakeCount: 0,
    alreadyExists: false,
  });
  const [stakersRecord, setStakersRecord] = useState([]);
  const tokenAddress = "0xFD9FacA15558eb243aC0012Cf7ACfBf480574668"

  //   const [toggleModal, setToggleModal] = useState(false);
  //   const [withdrawModal, setWithdrawModal] = useState(false);

  //   const [errorMsg, setErrorMsg] = useState("");
  //   const [isError, setIsError] = useState(false);

  const [isAllowance, setIsAllowance] = useState(false);
  //   const [isStaked, setIsStaked] = useState(true);
  const [loading, setLoadding] = useState(false);

  const notify = (isError, msg) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const checkAllowance = async (tokenAddress) => {
    try {
      setLoadding(true);

      var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
      var decimals = await tokenContract.methods.decimals().call();
      var getBalance = await tokenContract.methods.balanceOf(account).call();

      var pow = 10 ** decimals;
      var balanceInEth = getBalance / pow;
      setBalance(balanceInEth);
      var allowance = await tokenContract.methods
        .allowance(account, Router)
        .call();

      if (allowance <= 2) {
        setIsAllowance(true);
      }
      if (dipositAmount > 0) {
        var amount = dipositAmount * pow;
        if (allowance < amount) {
          setIsAllowance(true);
        }
      }
      setLoadding(false);
    } catch (err) {
      setLoadding(false);
    }
  };

  const approve = async (tokenAddress) => {
    setLoadding(true);
    try {
      var contract = new web3Obj.eth.Contract(WBNB, tokenAddress);

      var amountIn = 10 ** 69;
      amountIn = amountIn.toLocaleString("fullwide", { useGrouping: false });
      //   var amountIn = new web3Obj.utils.BigNumber("10").pow(69);

      await contract.methods
        .approve(Router, amountIn.toString())
        .send({ from: account })
        .then(() => {
          setIsAllowance(false);
          // checkAllowance(tokenAddress);
          setLoadding(false);
        });
    } catch (err) {
      console.log(err);
      setLoadding(false);
      notify(true, err.message);
    }
  };

  const addMetamask = async () => {
    const tokenAddress = Router;
    const tokenSymbol = 'MVRS';
    const tokenDecimals = 18;
    const tokenImage = "http://ec2-3-109-126-218.ap-south-1.compute.amazonaws.com/images/symbol.png"
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });
      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const stake = async (tokenAddress) => {
    if (isNaN(parseFloat(dipositAmount)) || parseFloat(dipositAmount) <= 0) {
      notify(true, "Error! please enter amount");
      return;
    }
    await checkAllowance(tokenAddress);
    setLoadding(true);
    try {
      var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
      const decimals = await tokenContract.methods.decimals().call();

      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);

      var pow = 10 ** decimals;
      var amountIn = dipositAmount * pow;
      // var amountInNew = `${new ethers.utils.BigNumber(amountIn.toString())}`;
      amountIn = amountIn.toLocaleString("fullwide", { useGrouping: false });

      await contract.methods
        .stake(amountIn.toString(), timeperiod.toString())
        .send({ from: account })
        .then((err) => {
          getStackerInfo(tokenAddress);
          setLoadding(false);
          notify(false, "Staking process complete.");
        });
    } catch (err) {
      setLoadding(false);
      notify(true, err.message);
    }
  };

  const unstake = async (index) => {
    setLoadding(true);
    try {
      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);
      await contract.methods
        .unstake(index.toString())
        .send({ from: account })
        .then((result) => {
          getStackerInfo(tokenAddress);
          setLoadding(false);
          notify(false, "successfully unstake");
          //   withdrawModal();
        });
    } catch (err) {
      setLoadding(false);
      notify(true, "unstake fail");
    }
  };

  const harvest = async (index) => {
    setLoadding(true);
    try {
      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);
      await contract.methods
        .harvest(index.toString())
        .send({ from: account })
        .then((err) => {
          getStackerInfo(tokenAddress);
          setLoadding(false);
          checkAllowance(tokenAddress);
          notify(false, "Reward successfully harvested");
        });
    } catch (err) {
      console.log(err);
      setLoadding(false);
      notify(true, err.message);
    }
  };

  const getStackerInfo = async (tokenAddress) => {
    setLoadding(true);
    try {
      var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
      var decimals = await tokenContract.methods.decimals().call();
      var getBalance = await tokenContract.methods
        .balanceOf(account.toString())
        .call();
      var pow = 10 ** decimals;
      var balanceInEth = getBalance / pow;
      setBalance(balanceInEth);

      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);
      var totalStakedToken = await contract.methods.totalStakedToken
        .call()
        .call();
      var totalStakers = await contract.methods.totalStakers.call().call();
      var realtimeReward = await contract.methods
        .realtimeReward(account)
        .call();
      var Stakers = await contract.methods.Stakers(account).call();

      var totalStakedTokenUser = Stakers.totalStakedTokenUser / pow;
      var totalUnstakedTokenUser = Stakers.totalUnstakedTokenUser / pow;
      var currentStaked = totalStakedTokenUser - totalUnstakedTokenUser;

      Stakers.totalStakedTokenUser = totalStakedTokenUser;
      Stakers.totalUnstakedTokenUser = totalUnstakedTokenUser;
      Stakers.currentStaked = currentStaked;
      Stakers.realtimeReward = realtimeReward / 10 ** 18;
      Stakers.totalClaimedRewardTokenUser =
        Stakers.totalClaimedRewardTokenUser / 10 ** 18;
      var stakersRecord = [];
      for (var i = 0; i < parseInt(Stakers.stakeCount); i++) {
        var stakersRecordData = await contract.methods
          .stakersRecord(account, i)
          .call();
        var realtimeRewardPerBlock = await contract.methods
          .realtimeRewardPerBlock(account, i.toString())
          .call();

        stakersRecordData.realtimeRewardPerBlock =
          realtimeRewardPerBlock[0] / 10 ** 18;

        stakersRecordData.unstaketime = moment
          .unix(stakersRecordData.unstaketime)
          .format("DD/MM/YYYY h:mm A");
        stakersRecordData.staketime = moment
          .unix(stakersRecordData.staketime)
          .format("DD/MM/YYYY h:mm A");
        stakersRecord.push(stakersRecordData);
      }
      setStakersInfo(Stakers);
      setStakersRecord(stakersRecord);
      setStackContractInfo({
        totalStakers: totalStakers,
        totalStakedToken: totalStakedToken / pow,
      });
      setLoadding(false);
    } catch (err) {
      console.log(err);
      setLoadding(false);
      setStakersInfo({
        totalStakedTokenUser: 0,
        totalUnstakedTokenUser: 0,
        totalClaimedRewardTokenUser: 0,
        currentStaked: 0,
        realtimeReward: 0,
        stakeCount: 0,
        alreadyExists: false,
      });
      setStackContractInfo({
        totalStakers: 0,
        totalStakedToken: 0,
      });
      setStakersRecord([]);
      setBalance(0);
    }
  };

  const setMaxWithdrawal = async () => {

    var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
    var decimals = await tokenContract.methods.decimals().call();
    var getBalance = await tokenContract.methods
      .balanceOf(account.toString())
      .call();
    var pow = 10 ** decimals;
    var balanceInEth = getBalance / pow;
    setDipositAmount(balanceInEth.toFixed(5));
    // setWithdrawAmount(userInfo.staked);
  };

  useEffect(() => {
    if (isConnected) {
      checkAllowance(tokenAddress);
      getStackerInfo(tokenAddress);
    } else {
      getStackerInfo(tokenAddress);
    }
  }, [isConnected, account]);
  return (
    <>

      <div>
        <div className="stack-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <div className="row">
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                    <div className="stack-box">
                      <div className="stack-header text-center">
                        <div className="float">
                          <div className="title1">
                            <h3 className="lineSpace ">TRADE</h3>
                          </div>
                        </div>
                      </div>
                      <div className="balance">
                        <a href="https://mvrstoken.tech/#/swap" target="_blank" className="listingBtnWrapper">
                          <img src="images/daoverse_swap_logo.png" className="listingButton" />
                        </a>
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0xA155464B1566cdDdf9782205602651B8B871b3d5" target="_blank" className="listingBtnWrapper">
                          <img src="images/pancakeswap whit.png" className="listingButton" />
                        </a>
                        <a href="https://coinsbit.io/trade/MVRS_USDT" target="_blank" className="listingBtnWrapper">
                          <img src="images/coinsbitio.png" className="listingButton" />
                        </a>
                        <a href="https://app.dodoex.io/exchange/ETH-USDC?network=mainnet" target="_blank" className="listingBtnWrapper">
                          <img src="images/dodo.png" className="listingButton" />
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                  <div className="stack-box">
                      <div className="stack-header text-center">
                        <div className="float">
                          <div className="title1">
                            <h3 className="lineSpace ">LISTINGS</h3>
                          </div>
                        </div>
                      </div>
                      <div className="balance">
                      <a href="https://swap.daoverse.site/#/swap" target="_blank" className="listingBtnWrapper">
                          <img src="images/daoverse_swap_logo.png" className="listingButton" />
                        </a>
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0xA155464B1566cdDdf9782205602651B8B871b3d5" target="_blank" className="listingBtnWrapper">
                          <img src="images/pancakeswap whit.png" className="listingButton" />
                        </a>
                        {/* <a href="https://crypto.com/price/meta-mvrs" target="_blank" className="listingBtnWrapper">
                          <img src="images/crypto_com.png" className="listingButton" />
                        </a>
                        <a href="https://www.coinbase.com/price/meta-mvrs" target="_blank" className="listingBtnWrapper">
                          <img src="images/coinbase.png" className="listingButton" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>

      
      <ToastContainer />
    </>
  );
}

export default Listings;
